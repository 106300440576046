const style = `
  <style>
  * {
    box-sizing: border-box;
  }

  a {
    cursor:pointer;
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
  }

  .ds-cookie-banner,
  .ds-cookie-banner.banner-type-full,
  .ds-cookie-banner.banner-theme-light {
    position: fixed;
    z-index: 999999;
    width: 100%;
    padding: 12px;

    background-color: #fbfbfb;
    border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    color: #1f2529;
  }

  /* Inner */
  .ds-cookie-banner-inner {
    max-width: 1024px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 5px;
  }

 .ds-cookie-banner-text {
    padding-right: 5px;
  }

  @media only screen and (max-width: 600px) {

    .ds-cookie-banner-text {
      padding: 0 0 12px;
    }

    .ds-cookie-banner-inner {
      flex-direction: column;
    }
  }

  /* Type Rewrite for Box */
  .ds-cookie-banner.banner-type-box {
    border: 1px solid #e2e2e2;
    box-shadow: 0 2px 4px rgb(49 55 60 / 25%);
    margin: 24px;
    max-width: 456px;
    width: calc(100% - 48px);
  }

 .banner-type-box .ds-cookie-banner-inner {
    flex-direction: column;
    padding: 18px;
  }

  .banner-type-box .ds-cookie-banner-text {
    text-align:center;
    margin-bottom: 16px;
  }


  /* Theme Rewrite for Dark */
  .ds-cookie-banner.banner-theme-dark {
      border: 0;
      background-color: #1f2529;
      color: #fff;
  }




  /* Positions */
  .ds-cookie-banner.banner-position-top {
       top: 0;
  }

  .ds-cookie-banner.banner-position-bottom {
       bottom: 0;
  }

  .ds-cookie-banner.banner-position-bottom_left {
       bottom: 0;
       left: 0;
  }

  .ds-cookie-banner.banner-position-bottom_right {
       bottom: 0;
       right: 0;
  }



  /* Buttons */
  .ds-cookie-banner-buttons {
    display: flex;
  }

  .ds-cookie-banner-buttons button{
    cursor: pointer;
    white-space: nowrap;
    margin: 5px;
    border-radius: 2px;
    border: 1px solid #1f2529;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    outline: none;
    padding: 0 16px;
    text-align: center;
  }

  /* Buttons - Secondary */
  .ds-cookie-banner-buttons button.secondary{
    background-color: #fff;
    color: #1f2529;
  }

  .ds-cookie-banner-buttons button.secondary:hover{
    background-color: rgba(65,76,84,.1);
  }

  .banner-theme-dark .ds-cookie-banner-buttons button.secondary{
      background-color: #1f2529;
      color: #fff;
      border: 1px solid #fff;
  }

  .banner-theme-dark .ds-cookie-banner-buttons button.secondary:hover{
     background-color: rgba(206,217,224,.1);
     border-color: #ced9e0;
  }



  /* Buttons - Primary */
  .ds-cookie-banner-buttons button.primary{
    background-color: #1f2529;
    color: #fff;
  }

  .ds-cookie-banner-buttons button.primary:hover{
    background-color: #414c54;
  }

 .banner-theme-dark .ds-cookie-banner-buttons button.primary{
    background-color: #fff;
    border: 1px solid #fff;
    color: #1f2529;
  }

  .banner-theme-dark .ds-cookie-banner-buttons button.primary:hover{
     background-color: #ced9e0;
     border-color: #ced9e0;
  }
  </style>
  `;

export type positions = 'bottom_left' | 'bottom_right' | 'top' | 'bottom';
export type types = 'box' | 'full';
export type themes = 'light' | 'dark';

export interface CookieBannerConfig {
  text: {
    main: string;
    acceptButton: string;
    declineButton: string;
  };
  styles?: {
    type: types;
    position: positions;
    theme: themes;
  };
  headerCode?: string;
  footerCode?: string;
  GA_ID?: string;
  FB_ID?: string;
  forceHide?: boolean;
}

export class CookieBanner {
  _cookieContainer: HTMLDivElement;

  randFunctionKey = 'DsCookieBanner';
  cookieName = 'accepts_cookies';

  alreadyInjected = false;

  accept = () => {
    this.injectCode();
    this.setCookie(this.cookieName, true);
    this.removeBanner();
  };

  decline = () => {
    this.setCookie(this.cookieName, false);
    this.removeBanner();
  };

  useShadowDom = true;

  constructor(public finalConfig: CookieBannerConfig) {
    this._cookieContainer = document.createElement('div');
    if (this.useShadowDom) {
      this._cookieContainer.attachShadow({
        mode: 'open',
      });
    }
    document.body.appendChild(this._cookieContainer);

    this.updateConfig(finalConfig);
  }

  updateConfig = (config: CookieBannerConfig) => {
    this.finalConfig = this.getFinalConfig(config);
  };

  getFinalConfig(finalConfig: CookieBannerConfig) {
    const type = finalConfig.styles?.type || 'full';
    return {
      text: {
        main: finalConfig.text.main,
        acceptButton: finalConfig.text.acceptButton,
        declineButton: finalConfig.text.declineButton,
      },
      styles: {
        type: type,
        position: (() => {
          const allowedValues: Partial<{ [key in types]: positions[] }> = {
            full: ['bottom', 'top'],
            box: ['bottom_left', 'bottom_right'],
          };
          const valuesToCheck = allowedValues[type];
          if (valuesToCheck.includes(finalConfig.styles?.position)) {
            return finalConfig.styles.position;
          } else {
            return valuesToCheck[0]; // [0] is default
          }
        })(),
        theme: finalConfig.styles?.theme || 'light',
      },
      headerCode: finalConfig.headerCode || '',
      footerCode: finalConfig.footerCode || '',
      GA_ID: finalConfig.GA_ID || '',
      FB_ID: finalConfig.FB_ID || '',
      forceHide: finalConfig.forceHide || false,
    };
  }

  /**
   * Cookie setzten
   * @param key
   * @param value
   * @param activeTime
   */
  setCookie(key: string, value: boolean, activeTime = 24 * 60 * 60 * 1000) {
    var d = new Date();
    const oneDay = d.getTime() + activeTime;
    d.setTime(oneDay * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = `${key}=${value}; ${expires};path=/`;
  }

  /**
   * Cookie suchen
   * @param name
   */
  getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2) return parts.pop().split(';').shift();
  }

  unEscape(htmlStr) {
    try {
      // https://handlebarsjs.com/examples/html-escaping.html
      htmlStr = htmlStr.replace(/&lt;/g, '<');
      htmlStr = htmlStr.replace(/&gt;/g, '>');
      htmlStr = htmlStr.replace(/&quot;/g, '"');
      htmlStr = htmlStr.replace(/&#x27;/g, "'");
      htmlStr = htmlStr.replace(/&amp;/g, '&');
      htmlStr = htmlStr.replace(/&#x60;/g, '`');
      htmlStr = htmlStr.replace(/&#x3D;/g, '=');
      return htmlStr;
    } catch (e) {
      console.log('fail to unescape', htmlStr);
      return null;
    }
  }

  /**
   * Javascript injection
   */
  async injectCode() {
    if (this.alreadyInjected) {
      return false;
    }

    const cleanupCode = (code: string) => {
      return code.replace(/<\\\/script>/g, '</script>').replace(/<\/script>/g, '</script>');
    };

    /* Those are only placeholder script containers, will be removed later on */
    const jsHeaderContainer = document.createElement('div');
    const jsFooterContainer = document.createElement('div');
    document.body.appendChild(jsHeaderContainer);
    document.body.appendChild(jsFooterContainer);

    // Load existing tracking codes
    try {
      let headerCode = this.unEscape(this.finalConfig.headerCode);
      let footerCode = this.unEscape(this.finalConfig.footerCode);

      if (this.finalConfig.GA_ID) {
        headerCode +=
          `<script>(function(e,t,n,i,s,a,c){e[n]=e[n]||function(){(e[n].q=e[n].q||[]).push(arguments)}
         ;a=t.createElement(i);c=t.getElementsByTagName(i)[0];a.async=true;a.src=s
         ;c.parentNode.insertBefore(a,c)
         })(window,document,"galite","script","/webinc/js/core/ga-lite.min.js");
         galite("create", "${this.finalConfig.GA_ID}", "auto");
         galite("send", "pageview");
         galite("set", "anonymizeIp", true);` + '<'.concat('/script>');
      }

      if (this.finalConfig.FB_ID) {
        headerCode +=
          `<script>!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,"script",
        "https://connect.facebook.net/en_US/fbevents.js");
        fbq("init", "${this.finalConfig.FB_ID}");
        fbq("track", "PageView");` + '<'.concat('/script>');
      }

      headerCode = cleanupCode(headerCode);
      footerCode = cleanupCode(footerCode);

      if (headerCode) {
        /**
         * insert everything eg. tracking-pixel, noscript, script etc
         * info: scripts will not exec.
         */
        jsHeaderContainer.innerHTML = headerCode;
        const children = Array.from(jsHeaderContainer.children) as HTMLScriptElement[];
        await this._loadScriptChildren(children, document.head);
      }

      if (footerCode) {
        jsFooterContainer.innerHTML = footerCode;
        const children = Array.from(jsFooterContainer.children) as HTMLScriptElement[];
        await this._loadScriptChildren(children, document.body);
      }

      // Cleanup dummy containers
      document.body.removeChild(jsHeaderContainer);
      document.body.removeChild(jsFooterContainer);

      this.alreadyInjected = true;
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }
  }

  private _loadScriptChildren(children: HTMLScriptElement[], parent: HTMLElement): Promise<void> {
    return children.reduce(async (promise, child: HTMLScriptElement) => {
      await promise;
      return new Promise<any>((resolve) => {
        if (child.tagName === 'SCRIPT') {
          // fetch scripts to re-execute
          const asyncScriptLoad = document.createElement('script') as HTMLScriptElement;
          if (child.src) {
            asyncScriptLoad.addEventListener('load', resolve);
            asyncScriptLoad.addEventListener('error', resolve);
            asyncScriptLoad.src = child.src;
          }
          if (child.innerHTML) {
            asyncScriptLoad.innerHTML = child.innerHTML;
          }

          parent.appendChild(asyncScriptLoad);

          if (!child.src) {
            resolve(null);
          }
        } else {
          // add all other scripts aswell
          parent.appendChild(child);
          resolve(null);
        }
      });
    }, Promise.resolve());
  }

  /**
   * Get Root container of the Banner
   */
  getContainer(): HTMLDivElement | ShadowRoot {
    return this.useShadowDom ? this._cookieContainer.shadowRoot : this._cookieContainer;
  }

  /**
   * Remove banner
   */
  removeBanner = () => {
    this.getContainer().innerHTML = '';
  };

  /**
   * Create a Banner in the container
   */
  openBanner = () => {
    this.getContainer().innerHTML = `
      ${style}
      <div class="ds-cookie-banner
      banner-type-${this.finalConfig.styles.type}
      banner-position-${this.finalConfig.styles.position}
      banner-theme-${this.finalConfig.styles.theme}
      ">
        <div class="ds-cookie-banner-inner">
          <div class="ds-cookie-banner-text">
              ${this.finalConfig.text.main}
          </div>
          <div class="ds-cookie-banner-buttons">
            <button class="secondary"  onClick="window.${this.randFunctionKey}.decline()">${this.finalConfig.text.declineButton}</button>
            <button class="primary"  onClick="window.${this.randFunctionKey}.accept()">${this.finalConfig.text.acceptButton}</button>
          </div>
        </div>
      </div>
      `;
  };

  /**
   * Render Banner
   */
  render() {
    window[this.randFunctionKey] = {
      accept: this.accept,
      decline: this.decline,
      reopen: this.openBanner,
      close: this.removeBanner,
      updateConfig: this.updateConfig,
    };

    const cookieValue = this.getCookie(this.cookieName);

    const isIframe = window.self !== window.top;

    /**
     * Wenn der Cookie existiert oder wir uns im einem Iframe befinden oder
     * forceHide true ist, rendern wir das JS einfach raus!
     */
    if (cookieValue === 'true' || isIframe || this.finalConfig.forceHide) {
      this.injectCode();
      return;
    }

    if (cookieValue === 'false') {
      return;
    }

    this.openBanner();
  }
}

try {
  /**
   * For local demo perps.
   */
  // || {
  //   text: {
  //     main: 'Bitte hier das durchlesen..',
  //     acceptButton: 'Akzeptieren',
  //     declineButton: 'Ablehnen',
  //   },
  //   styles: {
  //     type: 'box',
  //     position: 'bottom_right',
  //     theme: 'dark',
  //   },
  //   headerCode: '<script>console.log("hallo")<\\/script>',
  //   footerCode: '<script>console.log("Welt")</script>',
  //   GA_ID: '',
  //   FB_ID: '',
  //   forceHide: false,
  // }

  (function () {
    /* istanbul ignore next */
    if (window['cookieBannerConfig']) {
      new CookieBanner(window['cookieBannerConfig']).render();
    }
  })();
} catch (e) {
  console.log('error initialize cookie banner', e);
}
